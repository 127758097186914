<template>
  <div
    v-if="(videos || []).length > 0 && !loading"
    class="library-videos"
  >
    <div class="library-videos__container">
      <template
        v-for="video in videos"
        :key="video.id"
      >
        <LibraryVideoCard
          :video="video"
          :has-select="hasSelect"
          :has-remove="hasRemove"
          :component-id="componentId"
          @set-remove-video="getRemoveVideo"
        />
      </template>
    </div>

    <Pagination
      v-if="(videos || []).length"
      :data="paginationData"
      @navigate="page => setPage(page)"
    />
  </div>

  <div
    v-else-if="loading"
    class="warning"
  >
    Carregando..
  </div>

  <div
    v-else
    class="warning empty"
  >
    Você ainda não possui nenhum vídeo salvo na biblioteca, faça a importação de novos vídeos através do seu dispositivo.
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'LibraryVideos',

  props: {
    hasSelect: {
      type: Boolean,
      default: () => false
    },

    hasRemove: {
      type: Boolean,
      default: () => true
    },

    componentId: {
      type: String,
      default: () => 'library'
    }
  },

  emits: [
    'removeVideo'
  ],

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState(['libraryVideos', 'libraryVideosFilters']),

    videos () {
      return this.libraryVideos?.videos?.data || [];
    },

    paginationData (store) {
      const last_page    = store.libraryVideos?.videos?.last_page ?? 1
      const current_page = store.libraryVideos?.videos?.current_page ?? 1

      return {
        last_page,
        current_page
      }
    },

    setPage (store) {
      return (page) => store.libraryVideosFilters.page = page
    }
  },

  watch: {
    libraryVideosFilters: {
      deep: true,
      async handler () {
        this.searchLibraryVideos()
      }
    }
  },

  async created() {
    this.loading = true;
    await this.searchLibraryVideos();
    this.loading = false;
  },

  methods: {
    ...mapActions(['searchLibraryVideos']),

    getRemoveVideo (video) {
      this.$emit('removeVideo', video);
    }
  }
};
</script>

<style lang="scss" scoped>
.library-videos {
  display: flex;
  flex-flow: column;
  padding: 0 48px;
  margin-top: 50px;
  width: 100%;
  max-width: 100%;

  &__container {
    display: flex;
    flex-flow: wrap;
    gap: 15px;
    width: 100%;
  }

  .pagination {
    margin-top: 50px;
  }
}

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: $grayscale-500;
}
</style>