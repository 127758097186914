export default {
  namespaced: true,

  state: {
    newVeelsForm: {},
    newVeelsStep: '',
  },

  getters: {
    getNewVeelsForm: state => state.newVeelsForm,
    getNewVeelsStep: state => state.newVeelsStep
  },

  mutations: {
    SET_NEW_VEELS_FORM: (state, value) => {
      if (Object.keys(value || {}).length <= 0) {
        state.newVeelsForm = {};
        return;
      }

      Object.assign(state.newVeelsForm, {
        ...value
      })
    },
    SET_NEW_VEELS_STEP: (state, value) => (state.newVeelsStep = value)
  },

  actions: {
    setNewVeelsForm({ commit }, data) {
      commit('SET_NEW_VEELS_FORM', data);
    },

    setNewVeelsStep({ commit }, data) {
      commit('SET_NEW_VEELS_STEP', data);
    },

    async runStepsNewVeels ({ state, dispatch }) {
      let value = null;
      let error = null;

      try {
        const initCreation = await dispatch('runInitCreation');
        const veels_id = initCreation?.value?.data?.veels?.id;
        if (!veels_id) {
          return { error: 'Falha na criação do veels, tente novamente ou contate o suporte.' }
        }

        // const videos = [];
        // for (var i = 0; i < state.newVeelsForm.videos.length; i++) {
          // if (!state.newVeelsForm.videos[i]?.url) {
          //   var response = await dispatch('runUploadVideo', state.newVeelsForm.videos[i]);
          //   videos.push({ ...response?.value?.data?.video });
          //   response = null;
          // } else {
          //   videos.push({ ...state.newVeelsForm.videos[i] });
          // }
          // videos.push({ ...state.newVeelsForm.videos[i] });
        // }

        await dispatch('runLinkVideos', { videos: state.newVeelsForm.videos, veels_id })
        await dispatch('runLinkTerms', veels_id)

        value = await dispatch('runFinishCreation', veels_id);

      } catch (e) {
        console.error('[accountCreate]', error);
        error = e;
      }

      return {
        value: value?.value?.data,
        error
      };
    },

    async runInitCreation ({ commit }) {
      console.log(commit);
      let value = null;
      let error = null;

      try {
        value = await window
          .$request()
          .post(`/veels/save`);

      } catch (e) {
        error = e;
      }

      return {
        error,
        value
      };
    },

    async runUploadVideo ({ commit }, video) {
      console.log(commit);
      let value = null;
      let error = null;

      const formData = new FormData();
      formData.append('video', video);

      try {
        value = await window
          .$request()
          .post(`/gallery/save`, formData, { 
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

      } catch (e) {
        error = e;
      }

      return {
        error,
        value
      };
    },

    async runLinkVideos ({ commit }, { veels_id, videos }) {
      console.log(commit);
      let value = null;
      let error = null;
      let videosIds = [];

      videos.forEach((video) => {
        videosIds.push(video.id);
      })

      try {
        value = await window
          .$request()
          .post(`/link-videos/save`, {
            veels_id,
            videos: [...videosIds]
          });

      } catch (e) {
        error = e;
      }

      return {
        error,
        value
      };
    },

    async runLinkTerms ({ state }, veels_id) {
      let value = null;
      let error = null;

      try {
        value = await window
          .$request()
          .post(`/link-terms/save`, {
            veels_id,
            links: state.newVeelsForm.links
          });

      } catch (e) {
        error = e;
      }

      return {
        error,
        value
      };
    },
    

    async runFinishCreation ({ state }, veels_id) {
      let value = null;
      let error = null;

      try {
        value = await window
          .$request()
          .put(`/veels/${veels_id}`, {
            name: state.newVeelsForm?.name,
            description: state.newVeelsForm?.description
          });

      } catch (e) {
        error = e;
      }

      return {
        error,
        value
      };
    }
  }
}
