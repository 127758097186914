export default {
  namespaced: true,

  state: {
    bullets: {},
    newBulletsList: []
  },

  getters: {
    getBullets: state => state.bullets,
  },

  mutations: {
    SET_BULLETS: (state, value) => {
      Object.assign(state.bullets, { ...value })
    },

    SET_NEW_BULLETS_LIST: (state, item) => {
      const index = state.newBulletsList.findIndex((b) => b.bullet.id === item.bullet.id);

      if (index !== -1) {
        state.newBulletsList[index] = {...item};
      } else {
        state.newBulletsList.push(item);
      }
    },

    REMOVE_NEW_BULLETS_LIST: (state, id) => {
      const index = state.newBulletsList.findIndex((b) => b.bullet.id === id);
      state.newBulletsList.splice(index, 1);
    },
  },

  actions: {
    setNewBulletsList ({ commit }, data) {
      commit('SET_NEW_BULLETS_LIST', data);
    },

    removeNewBulletsList ({ commit }, id) {
      commit('REMOVE_NEW_BULLETS_LIST', id);
    },

    async createBullets ({ commit }, payload) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .post('/bullets/save/', payload)

        value = response.data;
        console.log(commit);
      } catch (e) {
        console.error('[createBullets]', error);
        error = e;
      }

      return {
        value,
        error
      };
    },

    async updateBullet ({ commit }, payload) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .put(`/bullets/${payload.id}`, payload)

        value = response.data;
        console.log(commit);
      } catch (e) {
        console.error('[updateBullet]', error);
        error = e;
      }

      return {
        value,
        error
      };
    },

    async removeBullet ({ commit }, id) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .delete(`/bullets/${id}`)

        value = response.data;
        console.log(commit);
      } catch (e) {
        console.error('[removeBullet]', error);
        error = e;
      }

      return {
        value,
        error
      };
    },

    async linkBullets ({ commit }, payload) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .put('/bullets/link', payload);

        value = response.data;
        console.log(commit);
      } catch (e) {
        console.error('[linkBullets]', error);
        error = e;
      }

      return {
        value,
        error
      };
    },

    // async searchCommentsDetails({ commit }, id) {
    //   let value = null;
    //   let error = null;

    //   try {
    //     const response = await window
    //       .$request()
    //       .get(`/comments/${id}`)

    //     commit('SET_COMMENTS_DETAILS', response.data?.comments || {});

    //     value = response.data;
    //   } catch (e) {
    //     console.error('[searchCommentsDetails]', error)
    //     error = e;
    //   }

    //   return {
    //     value,
    //     error
    //   };
    // },

    // async veelsLinkTerms ({ dispatch }, { veels_id, terms }) {
    //   let value = null;
    //   let error = null;

    //   try {
    //     value = await window
    //       .$request()
    //       .post(`/link-terms/save`, {
    //         veels_id,
    //         links: terms
    //       });

    //     dispatch('setVeelsDetails', {links: value?.data?.links || []});

    //   } catch (e) {
    //     error = e;
    //   }

    //   return {
    //     error,
    //     value
    //   };
    // },

    // async veelsUnlinkTerms ({ dispatch, state }, { veels_id, term_id }) {
    //   let value = null;
    //   let error = null;

    //   try {
    //     value = await window
    //       .$request()
    //       .delete(`/link-terms/${veels_id}/${term_id}`);

    //     const links = state.veelsDetails.links.filter(l => l.id !== term_id);
    //     dispatch('setVeelsDetails', {links: [...links]});

    //   } catch (e) {
    //     error = e;
    //   }

    //   return {
    //     error,
    //     value
    //   };
    // },

    // async removeVideoFromVeels({ dispatch, state }, data) {
    //   let value = null;
    //   let error = null;

    //   try {
    //     const response = await window
    //       .$request()
    //       .delete(`/link-videos/${data.veels_id}/${data.id}`)

    //     const videosData = state.veelsDetails.videos.filter(v => v.id !== data.id);

    //     dispatch('setVeelsDetails', {videos: videosData });

    //     value = response.data;
    //   } catch (e) {
    //     console.error('[removeVideoFromLibrary]', error)
    //     error = e;
    //   }

    //   return {
    //     value,
    //     error
    //   };
    // },
  }
}
