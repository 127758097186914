<template>
  <div class="upload-veels">
    <div class="upload-veels__actions">
      <ButtonImport
        for="upload-videos"
        icon="InboxUnarchiveLineIcon"
      >
        <template #text>
          Carregar do dispositivo
        </template>
      </ButtonImport>

      <ButtonImport
        icon="GalleryView2Icon"
        @click="toggleUploadFromLibrary(true)"
      >
        <template #text>
          Importar da biblioteca
        </template>
      </ButtonImport>

      <ButtonImport
        icon="SmartphoneLineIcon"
        @click="toggleUploadFromMobile(true)"
      >
        <template #text>
          Enviar do celular
        </template>
      </ButtonImport>
    </div>

    <FormKit
      id="upload-videos"
      type="file"
      accept="video/*"
      name="videos"
      multiple
      @change="handleFiles"
    />

    <div
      v-if="(newVeelsForm?.videos || []).length || loadingSkeleton > 0"
      class="upload-veels__videos"
    >
      <div
        class="list"
      >
        <div
          v-for="(video, index) in newVeelsForm?.videos"
          :key="index"
          class="item"
        >
          <video
            :src="video.url"
            controls
            width="320"
          />

          <span
            class="remove"
            @click="removeVideo(video)"
          >
            <DeleteBinLineIcon />
          </span>
        </div>

        <div
          v-for="skeleton in loadingSkeleton"
          :key="skeleton"
          class="upload-veels__skeleton"
        />
      </div>
      
      <!-- <label
        v-if="uploadedVideos.length < 5"
        class="add"
        for="upload-videos"
      >
        <AddLineIcon />
        <span class="text">Adicionar vídeo</span>
      </label> -->
    </div>

    <div
      v-else
      class="upload-veels__warning"
    >
      Carregue um vídeo a partir do seu dispositivo ou da biblioteca do veels.
    </div>

    <UploadFromLibrary
      v-if="isImportFromLibrary"
      @set-from-library="getVideosFromLibrary"
      @set-close-upload-from-library="closeUploadFromLibrary"
    />

    <UploadFromMobile
      v-if="isImportFromMobile"
      @set-close-upload-from-mobile="closeUploadFromMobile" 
    />

    <ButtonDefault
      :disabled="!checkedUploadStep"
      @click="setNewVeelsStep('bullets')"
    >
      Salvar e continuar
      <ArrowRightSLine />
    </ButtonDefault>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'UploadVeels',

  data: () => ({
    isImportFromLibrary: false,
    isImportFromMobile: false,
    loadingSkeleton: 0
  }),

  computed: {
    ...mapState(['newVeelsForm', 'newVeelsStep']),
    checkedUploadStep () {
      return (this.newVeelsForm?.videos || []).length > 0
    },
  },

  watch: {
    newVeelsStep (step) {
      if (step === 'success') {
        this.uploadedVideos = [];
      }
    },
  },

  mounted () {
    this.emitter.on('toggle-upload-from-library-new-veels', this.toggleUploadFromLibrary);
    this.emitter.on('toggle-upload-from-mobile-new-veels', this.toggleUploadFromMobile);
  },

  unmounted () {
    this.emitter.off('toggle-upload-from-library-new-veels', this.toggleUploadFromLibrary)
    this.emitter.off('toggle-upload-from-mobile-new-veels', this.toggleUploadFromMobile);
  },

  methods: {
    ...mapActions([
      'setNewVeelsForm', 
      'setNewVeelsStep', 
      'setLibraryVideosSelected', 
      'runUploadVideo',
      'removeVideoFromLibrary',
      'setNewBulletsList'
    ]),

    toggleUploadFromLibrary(status) {
      this.isImportFromLibrary = status;
    },

    closeUploadFromLibrary(status) {
      this.isImportFromLibrary = status;
      this.setLibraryVideosSelected({ video: {}, status: 'clear' });
    },

    getVideosFromLibrary (libraryVideosSelected) {
      const videos = (this.newVeelsForm?.videos || []).concat(libraryVideosSelected)
      this.isImportFromLibrary = false;

      this.setNewVeelsForm({
        videos: [...videos ]
      })

      let bullets = [];
      videos.forEach((video) => {
        if ((video?.bullets || []).length > 0) {
          bullets.push()
          this.setNewBulletsList({
            video,
            bullet: {...video.bullets[0]}
          })
        }
      })
    },

    toggleUploadFromMobile(status) {
      this.isImportFromMobile = status;
    },

    closeUploadFromMobile (status = false) {
      this.isImportFromMobile = status
    },

    async handleFiles(event) {
      const files = event.target.files;
      if (files) {
        let videos = [];
        this.loadingSkeleton = files.length

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.size <= 15 * 1024 * 1024) {
            const { value, error } = await this.runUploadVideo(file);

            if (error) {
              alert(`Parece que houve um problema com o video ${file.name}, faça o upload novamente.`);
            }

            videos.push({
              name: value?.data?.video?.name,
              url: value?.data?.video?.url,
              id: value?.data?.video?.id,
              isNew: true
            })

          } else {
            alert(`O arquivo ${file.name} excede o limite de 15MB e não foi adicionado.`);
          }
        }

        this.loadingSkeleton = 0;

        if ((this.newVeelsForm?.videos || []).length > 0) {
          videos = (this.newVeelsForm?.videos || []).concat(videos)
        }

        this.setNewVeelsForm({
          videos: [...videos]
        });
        event.target.value = null;
      }
    },

    async removeVideo(video) {
      if (video.isNew) {
        const { error } = await this.removeVideoFromLibrary({...video});
        if (error) {
          return alert('Falha ao remover o vídeo, tente novamente ou recarregue a página');
        }
      }

      const videos = this.newVeelsForm.videos.filter(v => v.id != video.id);

      this.setNewVeelsForm({
        videos: [...videos]
      })
    },

    setImportFromLibrary () {
      this.isImportFromLibrary = !this.isImportFromLibrary;
    }
  }
}
</script>

<style lang="scss">
.upload-veels {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 50px;

  &__actions {
    display: flex;
    flex-flow: nowrap;
    gap: 44px;

    .button-import {
      width: 140px;
    }
  }

  &__warning {
    margin-top: 50px;
    max-width: 500px;
    line-height: 25px;
    font-size: 15px;
    color: $grayscale-400;
  }

  .count {
    margin: 30px 0;
    font-size: 15px;
    color: $grayscale-500;

    &.max {
      color: $blue-500;
    }
  }

  .formkit-outer {
    display: none;
  }

  &__videos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 70px;
    height: inherit;
    gap: 20px;

    .list {
      width: auto;
      max-width: 800px;
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      overflow-x: auto;
      gap: 20px;
      height: 210px;

      .item {
        min-width: 140px;
        width: 140px;
        height: 185px;
        position: relative;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }

        .remove {
          position: absolute;
          display: flex;
          top: 10px;
          right: 10px;
          z-index: 1;
          background-color: $white;
          width: 24px;
          height: 24px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;

          svg {
            color: $red-500;
            width: 15px;
          }
        }
      }
    }

    // .add {
    //   border: 1px dashed $black-500;
    //   padding: 10px;
    //   border-radius: 20px;
    //   display: flex;
    //   flex-flow: column;
    //   align-items: center;
    //   justify-content: center;
    //   width: 120px;
    //   height: 150px;
    //   gap: 10px;
    //   cursor: pointer;
      
    //   svg {
    //     width: 25px;
    //     color: $black-500;
    //   }

    //   .text {
    //     font-size: 13px;
    //     color: $black-500;
    //   }
    // }
  }

  &__skeleton {
    width: 140px;
    min-width: 140px;
    height: 185px;
    border-radius: 20px;
    background: $grayscale-300;
    animation: blink 1s infinite;
    gap: 20px;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  .button-default {
    width: 212px;
    position: absolute;
    right: 85px;
    bottom: 40px;
  }
}
</style>