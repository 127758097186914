<template>
  <div class="bullets-form-veels">
    <div class="bullets-form-veels__header">
      <span
        class="back"
        @click="$emit('close-bullets-form', false)"
      >
        <ArrowRightSLine />
        Voltar
      </span>

      <strong class="title">
        Tabela de informações
      </strong>
    </div>

    <div class="bullets-form-veels__content">
      <div
        type="form"
        class="bullets-form-veels__form"
        :actions="false"
      >
        <div class="field">
          <label class="field-label">Título da tabela</label>
          <input
            v-model="formData.title"
            name="title"
            type="text"
            placeholder="Ex.: Tabela de medidas"
          >
        </div>

        <div class="fields-group">
          <label class="field-label">Ícone do menu lateral</label>
          <div class="icons">
            <div class="icon">
              <input
                id="ruler-line-icon"
                v-model="formData.icon"
                value="ruler-line"
                name="icon"
                type="radio"
              >
              <label
                class="icon-label"
                for="ruler-line-icon"
              >
                <RulerLineIcon />
              </label>
            </div>
            
            <div class="icon">
              <input
                id="table-view-icon"
                v-model="formData.icon"
                value="table-view"
                name="icon"
                type="radio"
              >
              <label
                class="icon-label"
                for="table-view-icon"
              >
                <TableViewIcon />
              </label>
            </div>
          </div>
        </div>

        <div class="field data">
          <label class="field-label">Dados da tabela</label>
          <div 
            v-for="(field, index) in formData.table" 
            :key="index"
            class="fields-group"
          >
            <input
              v-model="field.title"
              name="title"
              placeholder="Título"
            >
            <input
              v-model="field.value"
              name="value"
              placeholder="Valor"
            >

            <button
              v-if="index > 0" 
              type="button"
              class="remove-column"
              @click="removeTableField(index)"
            >
              <DeleteBinLineIcon />
            </button>
          </div>

          <button
            v-if="(formData.table || []).length < 5"
            type="button"
            class="add-column"
            @click="setTableField"
          >
            + Nova coluna
          </button>
        </div>

        <div class="field">
          <label class="field-label">Descrição (opcional)</label>
          
          <textarea
            v-model="formData.description"
            name="description"
            type="text"
            placeholder="Ex.: Modelo veste M"
          />
        </div>

        <div class="field videos">
          <label
            v-if="!formData.id"
            class="field-label"
          >Quais vídeos pertencem a esta tabela?</label>

          <label
            v-else
            class="field-label"
          >Video pertencente à esta tabela</label>

          <div class="videos-list">
            <div
              v-for="video in enabledVideosList"
              :key="video.id"
              class="video"
            >
              <input
                v-if="!formData.id"
                :id="`${video.id}-video`"
                v-model="videos"
                :value="{...video }"
                name="icon"
                type="checkbox"
              >

              <label
                class="video-label"
                :for="`${video.id}-video`"
              >
                <span
                  v-if="!formData.id"
                  class="check"
                />

                <video>
                  <source
                    :src="video.url"
                    type="video/mp4"
                  > 
                  Seu navegador não suporta a tag de vídeo. 
                </video>

                <span class="video-text">
                  {{ video.name }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="actions">
          <ButtonDefault
            v-if="Object.keys(bullet || {}).length > 0"
            class="remove"
            type="button"
            @click="removeBulletDataConfirm"
          >
            <DeleteBinLineIcon />
            Excluir
          </ButtonDefault>

          <ButtonDefault
            type="button"
            @click="createOrUpdateBullet"
          >
            Salvar
          </ButtonDefault>
        </div>
      </div>

      <div class="bullets-form-veels__preview">
        <strong class="preview-title">Visualização</strong>
        <span class="icon">
          <label
            class="icon-label"
          >
            <RulerLineIcon v-if="formData.icon === 'ruler-line'" />
            <TableViewIcon v-if="formData.icon === 'table-view'" />
          </label>
          <span class="icon-title">{{ formData.title }}</span>
        </span>
        <div class="data">
          <span class="title">
            {{ formData.title || '-' }}
          </span>

          <div class="table">
            <div
              v-for="(data, index) in formData.table"
              :key="index"
              class="table-data"
            >
              <span class="table-name">{{ data.title }}</span>
              <span class="table-value">{{ data.value }}</span>
            </div>
          </div>

          <div class="description">
            {{ formData.description || "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'BulletsFormVeels',

  props: {
    bullet: {
      type: Object,
      default: () => {}
    },

    enabledVideos: {
      type: Array,
      default: () => []
    }
  },

  emits: ['close-bullets-form', 'setBulletsForm', 'notify-updated-bullet'],

  data: () => ({
    videos: [],
    formData: {
      title: "",
      icon: "",
      description: "",
      table: [
        {title: '', value: ''}
      ],
    }
  }),

  computed: {
    ...mapState(['newVeelsForm', 'newBulletsList']),

    enabledVideosList () {
      if (Object.keys(this.bullet || {}).length > 0) {
        return [this.bullet.video]
      }

      return this.enabledVideos || []
    }
  },

  mounted() {
    if (Object.keys(this.bullet || {}).length > 0) {
      this.formData = { ...this.bullet.bullet }
      this.videos = [this.bullet.video]
    }
  },

  methods: {
    ...mapActions([
      'createBullets',
      'linkBullets',
      'runStepsNewVeels', 
      'setNewBulletsList',
      'removeNewBulletsList',
      'updateBullet',
      'removeBullet'
    ]),

    formValidation () {
      return this.formData.title &&
      this.formData.icon &&
      this.formData.table[0].title &&
      this.formData.table[0].value &&
      (this.videos || []).length > 0
    },

    async createOrUpdateBullet () {
      if (!this.formValidation()) {
        return alert('Preencha as informações correamente');
      }

      if (Object.keys(this.bullet || {}).length > 0) {
        let response = await this.updateBullet({...this.formData})
        alert('Tabela atualizada com sucesso!');

        this.$emit('notify-updated-bullet', {...response.value?.bullet});

        if (!response?.error && Object.keys(this.newVeelsForm, {}).length > 0) {
          this.setNewBulletsList({
            bullet: {...response.value?.bullet},
            video: {...this.enabledVideos[0]}
          })
        }
      } else {
        const promises = this.videos.map(async (video) => {
          let bullet = await this.createBullets({ ...this.formData });

          if (!bullet?.error) {
            let link = await this.linkBullets({
              bullets: [bullet?.value?.bullet?.id],
              videos: [video.id],
            });

            if (!link?.error && Object.keys(this.newVeelsForm, {}).length > 0) {
              this.setNewBulletsList({
                bullet: { ...bullet.value?.bullet },
                video,
              });
            }
          }
        });

        await Promise.all(promises);
      }

      this.$emit('close-bullets-form', false)
    },

    setTableField () {
      this.formData.table.push(
        {title: '', value: ''}
      )
    },

    removeTableField (index) {
      this.formData.table.splice(index, 1)
    },

    async removeBulletDataConfirm() {
      const confirmRemove = confirm("Você tem certeza de que deseja excluir essa tabela ?");

      if (confirmRemove) {
        await this.removeBullet(this.bullet.bullet.id);
        this.removeNewBulletsList(this.bullet.bullet.id);
        this.$emit('close-bullets-form', false)
      }
    }
  }
}
</script>

<style lang="scss">
.bullets-form-veels {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  z-index: 10;
  width: 90vw;
  height: 90vh;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  padding: 0;
  overflow-y: auto;

  &__header {
    display: flex;
    width: 100%;
    min-height: 100px;
    align-items: center;
    gap: 50px;
    position: sticky;
    top: 0;
    background: $white;
    border-radius: 10px 10px 0 0;
    padding: 0 110px;

    .back {
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;

      svg {
        transform: rotate(-180deg);
        width: 12px;
        height: 12px;
        path {
          stroke: $black-500;
        }
      }
    }

    .title {
      font-size: 20px;
    }
  }

  &__content {
    display: flex;
    flex-flow: nowrap;
    widows: 100%;
    gap: 100px;
  }

  &__preview {
    margin-top: 20px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    position: sticky;
    top: 100px;
    height: max-content;

    .preview-title {
      font-size: 17px;
      margin-bottom: 20px;
    }

    .icon {
      position: absolute;
      top: -25px;
      right: 0;
      border: 1px solid darken($black-500, 100%);
      width: 40px;
      height: 40px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      svg {
        color: darken($black-500, 100%);
        width: 20px;
      }

      &-label {
        display: flex;
        flex-flow: column;
      }

      &-title {
        font-size: 12px;
        position: absolute;
        bottom: -18px;
        white-space: nowrap;        
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50px;
      }
    }

    .data {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      gap: 20px;
      width: 390px;
      background: darken($black-500, 100%);
      border-radius: 10px;
      padding: 20px;

      .title {
        font-size: 17px;
        color: $white;
      }

      .table {
        display: flex;
        flex-flow: nowrap;
        width: 100%;
        gap: 1px;

        &-data {
          display: flex;
          flex-flow: column;
          width: 100%;
          gap: 0px;
        }
        &-name {
          padding: 10px;
          background: #ffffff1f;
          color: $grayscale-400;
          font-size: 11px;
          border-radius: 5px 5px 0 0;
          min-height: 35px;
        }
        &-value {
          padding: 10px;
          background: #ffffff1f;
          color: $white;
          font-size: 13px;
          min-height: 35px;
          border-radius: 0px 0px 5px 5px;
        }
      }

      .description {
        color: #cbcbcb;
        font-size: 14px;
        text-align: left;
      }
    }

  }

  &__form {
    display: flex;
    flex-flow: column;
    width: max-content;
    border-radius: 8px;
    gap: 20px;
    width: 550px;
    padding-left: 112px;
    margin-top: 20px;

    .field, .fields-group {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;

      textarea {
        height: 100px;
        padding-top: 10px;
      }
    }

    .data {
      gap: 10px;

      input {
        max-width: calc(50% - 20px);
      }

      .fields-group {
        flex-flow: nowrap;
        gap: 10px;
      }

      .add-column {
        width: max-content;
        align-self: flex-end;
        height: auto;
        cursor: pointer;
        padding: 0 20px;
        background: transparent;
        border: unset;
        color: $black-500;
        text-decoration: underline;
        margin-right: 10px;
      }

      .remove-column {
        width: 40px;
        background: transparent;
        border: none;
        svg {
          color: $red-500;
          width: 20px;
        }
      }
    }

    .field-label {
      width: max-content;
      margin-bottom: 5px;
      color: $black-500;
    }

    .icons {
      display: flex;
      flex-flow: nowrap;
      gap: 10px;
      margin-top: 10px;

      .icon {
        input {
          display: none;
          &:checked {
            & + .icon-label {
              background: $black-500;
              color: $white;

              svg {
                color: $white;
              }
            }
          }
        }

        &-label {
          cursor: pointer;
          border-radius: 50%;
          background: $white;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid darken($black-500, 100%);

          svg {
            color: darken($black-500, 100%);
            width: 20px;
          }
        }
      }
    }

    .videos {
      &-list {
        display: flex;
        flex-flow: wrap;
        gap: 10px;
        height: auto;

        input {
          display: none;

          &:checked {
            & + .video-label {
              .check {
                background: $blue-500;
                border-color: $blue-500;

                &:after {
                  content: "";
                  width: 13px;
                  height: 5px;
                  border-left: 4px solid $white;
                  border-bottom: 4px solid $white;
                  transform: rotate(-45deg);
                  left: 7px;
                  top: 7px;
                  position: absolute;
                }
              }
            }
          }
        }

        .video {
          width: 100px;
          gap: 10px;
          display: flex;
          position: relative;
          overflow: hidden;

          &-label {
            display: flex;
            flex-flow: column;
            flex-flow: column;
            gap: 5px;

            .check {
              width: 30px;
              height: 30px;
              background: $white;
              position: absolute;
              left: 10px;
              top: 10px;
              border-radius: 4px;
              border: 1px solid $grayscale-300;
            }
          }

          video {
            width: 100px;
            height: 150px;
            object-fit: cover;
            border-radius: 10px;
            pointer-events: none;
            border: 1px solid $grayscale-300;
          }

          &-text {
            font-size: 13px;
          }
        }
      }
    }

    .actions {
      position: sticky;
      bottom: 0;
      height: 60px;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-radius: 10px 10px 0 0;
      gap: 30px;

      .button-default {
        align-items: center;
        justify-content: center;
        height: 40px;
        max-width: 150px;

        &.remove {
          border: 1px solid $red-500;
          color: $red-500;
          gap: 10px;
          background: transparent;
          width: max-content;
        }
      }
    }
  }
}
</style>