<template>
  <div class="product-veels">
    <div class="product-veels__content">
      <div class="product-veels__content-search">
        <div class="field">
          <SearchLineIcon />

          <FormKit
            v-model="searchValue"
            type="text"
            placeholder="Digite o SKU ou cole a URL"
          />
        </div>

        <div
          v-if="searchValue"
          class="cards"
        >
          <!-- <div
            v-for="i in 3"
            :key="i"
            class="card"
          >
            <img
              src="@/assets/img/veels-card.png"
              alt="T-shirt"
            >

            <span class="name">
              T-shirt listrada 100% algodão
            </span>

            <button class="link">
              Vincular
            </button>
          </div> -->

          <div
            class="card"
          >
            <span class="icon">
              <AddLineIcon />
            </span>

            <span class="name">
              {{ searchValue }}
            </span>

            <button
              class="link"
              @click="setSearchedProduct"
            >
              Adicionar
            </button>
          </div>
        </div>

        <div
          v-else
          class="empty"
        >
          Encontre/adicione seus produtos para vincular aos seus veels.
        </div>
      </div>

      <div class="product-veels__content-linkeds">
        <strong class="title">Produtos vinculados</strong>

        <div
          v-if="(newVeelsForm?.links || []).length"
          class="cards"
        >
          <!-- <div
            v-for="i in 10"
            :key="i"
            class="card"
          >
            <img
              src="@/assets/img/veels-card.png"
              alt="T-shirt"
            >

            <span class="name">
              T-shirt listrada 100% algodão
            </span>

            <button class="unlink">
              Desvincular
            </button>
          </div> -->

          <div
            v-for="(item, i) in newVeelsForm?.links"
            :key="i"
            class="card"
          >
            <span class="icon">
              {{ detectURLOrSKU(item.value) }}
            </span>

            <!-- <img
              src="@/assets/img/veels-card.png"
              alt="T-shirt"
            > -->

            <span class="name">{{ item.value }}</span>

            <button
              class="unlink"
              @click="removeProduct(i)"
            >
              Desvincular
            </button>
          </div>
        </div>

        <div
          v-else
          class="empty"
        >
          Você ainda não adicionou nenhum produto
        </div>
      </div>
    </div>

    <ButtonDefault
      class="back"
      @click="setNewVeelsStep('bullets')"
    >
      <ArrowRightSLine />
      Voltar
    </ButtonDefault>

    <ButtonDefault
      :disabled="!checkedProductStep"
      @click="setNewVeelsStep('publish')"
    >
      Revisar conteúdo
      <ArrowRightSLine />
    </ButtonDefault>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'ProductVeels',

  data: () => ({
    searchValue: '',
    searchValues: []
  }),

  computed: {
    ...mapState(['newVeelsForm']),
    checkedProductStep () {
      return (this.newVeelsForm?.links || []).length > 0
    },
  },

  methods: {
    ...mapActions(['setNewVeelsForm', 'setNewVeelsStep']),
    setSearchedProduct () {
      let links = [...this.newVeelsForm?.links || []];
      links.push({
        value: this.searchValue, 
        attribute: this.detectURLOrSKU(this.searchValue)
      });

      this.setNewVeelsForm({ links })

      this.searchValue = '';
    },

    removeProduct(index) {
      let links = [...this.newVeelsForm.links];
      links.splice(index, 1);

      this.setNewVeelsForm({ links })
    },

    detectURLOrSKU(url) {
      let regexRelative = /^\/.*/;
      if (regexRelative.test(url)) {
        return 'url';
      }

      let regexCompleteURL = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*(\?[\w\-=&]*)?$/;

      if (regexCompleteURL.test(url)) {
        return 'url';
      }

      return 'sku';
    },
  },
}
</script>

<style lang="scss">
.product-veels {
  display: flex;
  flex-flow: column;
  align-items: center;

  &__content {
    display: flex;
    flex-flow: nowrap;
    gap: 50px;

    &-search {
      gap: 25px;
      display: flex;
      flex-flow: column;
      align-items: center;

      .field {
        display: flex;
        position: relative;
        width: max-content;
        height: 40px;
        width: 342px;
        padding: 0 25px;
        padding-left: 55px;
        border: 1px solid $grayscale-300;
        border-radius: 30px;
        align-items: center;

        svg {
          position: absolute;
          left: 20px;
          width: 20px;
          color: $grayscale-400;
        }

        .formkit-outer {
          .formkit-wrapper {
            .formkit-inner {
              input {
                width: 100%;
                height: 38px;
                border: 0;
                color: $black-500;

                &::placeholder {
                  color: $grayscale-300;
                }
              }
            }
          }
        }
      }
    }

    .cards {
      display: flex;
      flex-flow: column;
      gap: 10px;
      max-height: 315px;
      overflow-y: auto;
      width: 382px;
      align-items: flex-start;

      .card {
        background: $grayscale-100;
        padding: 10px 24px;
        border-radius: 10px;
        display: flex;
        flex-flow: nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        width: calc(100% - 20px);

        .icon {
          width: 48px;
          height: 48px;
          background: $grayscale-200;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          text-transform: uppercase;

          svg {
            color: $grayscale-500;
            width: 23px;
            height: 23px;
          }
        }

        img {
          width: 48px;
          height: 48px;
          object-fit: cover;
          border-radius: 4px;
        }

        .name {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $black-500;
          width: calc(100% - 48px - 20px - 15px - 34px);
          text-align: left;
        }

        .link, .unlink {
          color: $blue-500;
          font-weight: 500;
          background: unset;
          border: unset;
          cursor: pointer;
        }

        .unlink {
          color: $red-500;
        }
      }
    }

    &-linkeds {
      border: 1px solid $grayscale-300;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 40px;
      min-width: 424px;

      .title {
        color: $black-500;
      }

      .cards {
        .card {
          background: transparent;
          padding: 0;

          .name {
            width: calc(100% - 140px);
            text-align: left;
          }
        }
      }
    }
  }

  .button-default {
    width: 212px;
    position: absolute;
    right: 85px;
    bottom: 40px;

    &.back {
      left: 85px;
      right: unset;
      justify-content: flex-start;
      gap: 20px;
      width: max-content;
      background: transparent;
      color: $black-500;

      svg {
        transform: rotate(180deg);
        width: 12px;
        height: 12px;

        path:last-child {
          stroke: $black-500;
        }
      }
    }
  }

  .empty {
    font-size: 15px;
    color: $grayscale-400;
    max-width: 300px;
    line-height: 25px;
  }
}
</style>