<template>
  <div class="bullets-veels">
    <BulletsForm
      v-if="isCreatingOrUpdateTable"
      :enabled-videos="enabledVideos"
      :bullet="bulletToEdit"
      @close-bullets-form="toggleBulletsForm"
    />

    <div class="bullets-veels__list">
      <div
        v-if="newBulletsList.length > 0"
        class="items"
      >
        <div
          v-for="item in newBulletsList"
          :key="item.bullet.id"
          class="item"
        >
          <div
            class="video"
          >
            <video>
              <source
                :src="item.video?.url"
                type="video/mp4"
              > 
              Seu navegador não suporta a tag de vídeo. 
            </video>

            <span class="video-name">
              {{ item.video?.name }}
            </span>
          </div>

          <div class="info">
            <span class="name">
              {{ item.bullet.title }}
            </span>

            <span class="description">
              {{ item.bullet.description }}
            </span>
          </div>

          <div class="table">
            <div
              v-for="(data, index) in item.bullet.table"
              :key="index"
              class="table-data"
            >
              <span class="table-name">{{ data.title }}</span>
              <span class="table-value">{{ data.value }}</span>
            </div>
          </div>

          <div class="actions">
            <span
              class="remove"
              @click="removeBulletItem(item)"
            >
              <DeleteBinLineIcon />
            </span>

            <span
              class="edit"
              @click="editBullet(item)"
            >
              <EditBoxLineIcon />
            </span>
          </div>
        </div>

        <ButtonDefault
          v-if="(newBulletsList || []).length < (newVeelsForm.videos || []).length"
          class="new-table"
          @click="toggleBulletsForm(true)"
        >
          + Criar nova tabela
        </ButtonDefault>
      </div>

      <div
        v-else
        class="empty"
      >
        <strong class="empty-title">
          Quer adicionar informações extras para seus vídeos?
        </strong>

        <p class="empty-description">
          Para descrever um pouco mais sobre o produto mencionado nos vídeos, 
          você pode criar uma tabela de características do produto, 
          agregando informações importantes que ajudam no entendimento dos seus clientes.
          <br><br>

          Vou te dar um exemplo: Para uma peça de roupa, você pode descrever na tabela as características
          da modelo que está vestindo, como largura da cintura, quadril, busto e outros. E informar qual é o tamanho
          que a modelo está usando em seu corpo no vídeo. Exemplo: A modelo veste "M"
        </p>

        <ButtonDefault @click="toggleBulletsForm(true)">
          + Criar tabela de informações
        </ButtonDefault>
      </div>
    </div>

    <ButtonDefault
      class="back"
      @click="setNewVeelsStep('upload')"
    >
      <ArrowRightSLine />
      Voltar
    </ButtonDefault>

    <div class="bullets-veels__actions">
      <ButtonDefault
        class="ignore"
        @click="setNewVeelsStep('product')"
      >
        Pular
      </ButtonDefault>

      <ButtonDefault
        :disabled="!hasBullets"
        :loading="loading"
        @click="setNewVeelsStep('product')"
      >
        Continuar
        <ArrowRightSLine />
      </ButtonDefault>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BulletsForm from "../global/BulletsForm.vue"

export default {
  name: 'BulletsVeels',

  components: {
    BulletsForm
  },

  data: () => ({
    loading: false,
    isCreatingOrUpdateTable: false,
    bulletToEdit: {}
  }),

  computed: {
    ...mapState(['newBulletsList', 'newVeelsForm']),
    hasBullets () {
      return (this.newBulletsList || []).length > 0
    },

    enabledVideos () {
      const newVeelsEnabledVideos = this.newVeelsForm.videos.filter(video => (video.bullets || []).length < 1)

      return newVeelsEnabledVideos.filter(video =>
        !(this.newBulletsList || []).some(vbullet => vbullet.video.id === video.id)
      );
    }
  },

  methods: {
    ...mapActions([
      'setNewVeelsStep', 
      'setNewVeelsForm', 
      'runStepsNewVeels',
      'removeBullet',
      'removeNewBulletsList'
    ]),

    toggleBulletsForm (status) {
      if (!status) {
        this.bulletToEdit = {}
      }

      this.isCreatingOrUpdateTable = !!status;
    },

    editBullet (item) {
      this.bulletToEdit = {...item}
      this.toggleBulletsForm(true);
    },

    async removeBulletItem (item) {
      let response = await this.removeBullet(item.bullet.id)

      if (!response?.error) {
        let videos = [...this.newVeelsForm.videos];

        videos.forEach(video => {
          if (video.id === item.video.id) {
            video.bullets = [];
          }
        });

        this.setNewVeelsForm({
          videos: [...videos]
        })
        
        return this.removeNewBulletsList(item.bullet.id);
      }

      alert('Falha ao remover a tabela, recarregue a página e tente novamente.')
    }
  }
}
</script>

<style lang="scss">
.bullets-veels {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 20px;
  overflow-y: auto;
  max-height: calc(100% - 290px);

  &__list {
    display: flex;
    flex-flow: column;

    .items {
      display: flex;
      flex-flow: column;
      gap: 20px;

      .item {
        display: flex;
        flex-flow: nowrap;
        justify-content: flex-start;
        width: max-content;
        border-radius: 20px;
        padding: 20px;
        padding-right: 70px;
        gap: 10px;
        background: $grayscale-100;
        border: 1px solid $grayscale-200;
        position: relative;

        .info {
          display: flex;
          flex-flow: column;
          gap: 10px;
          margin-right: 10px;
          height: 100%;
          width: 200px;

          .name {
            width: max-content;
            font-size: 17px;
            font-weight: bold;
            color: $black-500;
          }

          .description {
            width: max-content;
            font-size: 13px;
            max-width: 100%;
            text-align: left;
            line-height: 17px;
            color: $black-500;
          }
        }

        .table {
          display: flex;
          flex-flow: nowrap;
          gap: 1px;

          &-data {
            display: flex;
            flex-flow: column;
            gap: 0px;
            width: 100%;
            max-width: 120px;
            min-width: 100px;
          }
          &-name {
            padding: 10px;
            background: $grayscale-200;
            color: $black-500;
            font-size: 11px;
            border-radius: 5px 5px 0 0;
            min-height: 35px;
          }
          &-value {
            padding: 10px;
            background: $grayscale-200;
            color: $black-500;
            font-weight: bold;
            font-size: 13px;
            min-height: 35px;
            border-radius: 0px 0px 5px 5px;
          }
        }

        .video {
          display: flex;
          flex-flow: column;
          max-width: 100px;
          width: 100px;
          height: 120px;
          gap: 5px;

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            max-height: 100px;
            pointer-events: none;
          }

          &-name {
            font-size: 13px;
            color: $black-500;
            white-space: nowrap;        
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 95px;
          }
        }

        .actions {
          align-self: flex-end;
          position: absolute;
          top: 20px;
          right: 20px;
          display: flex;
          flex-flow: column;
          gap: 5px;

          .remove, .edit {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            cursor: pointer;

            svg {
              width: 20px;
              height: 20px;
              color: $red-500;
            }
          }

          .edit {
            svg {
              width: 15px;
              height: 15px;

              path {
                stroke: $black-500;
              }
            }
          }
        }
      }
    }

    .empty {
      display: flex;
      flex-flow: column;
      align-items: center;
      gap: 20px;

      &-title {
        font-size: 19px;
      }
      &-description {
        font-size: 16px;
        max-width: 700px;
        line-height: 25px;
      }
      .button-default {
        width: max-content;
        padding: 0 50px;
        margin-top: 40px;
      }
    }
  }

  &__actions {
    display: flex;
    flex-flow: nowrap;
    position: absolute;
    right: 85px;
    bottom: 40px;
    gap: 20px;

    .button-default {
      &.ignore {
        max-width: 100px;
        justify-content: center;
        background: transparent;
        color: $black-500;
      }
    }
  }

  .button-default {
    width: 212px;
    background: $primary;

    &.new-table {
      justify-content: center;
      align-self: flex-end;
      background: transparent;
      border: 1px solid $black-500;
      color: $black-500;
      height: 35px;
      width: 145px;
      padding: 0;
    }

    &.back {
      left: 85px;
      bottom: 40px;
      right: unset;
      justify-content: flex-start;
      gap: 20px;
      width: max-content;
      background: transparent;
      color: $black-500;
      position: absolute;

      svg {
        transform: rotate(180deg);
        width: 12px;
        height: 12px;

        path:last-child {
          stroke: $black-500;
        }
      }
    }
  }
}
</style>