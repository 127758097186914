<template>
  <div
    class="library-video__card"
  >
    <video
      class="preview"
      controls
    >
      <source
        :src="video?.url"
        type="video/mp4"
      >
      Seu navegador não suporta a tag de vídeo.
    </video>

    <input
      :id="`${componentId}-${video.id}`"
      v-model="selected"
      type="checkbox"
      name="video"
      @change="setVideoSelected(video, selected)"
    >
    <label
      v-if="hasSelect"
      class="check"
      :for="`${componentId}-${video.id}`"
    />

    <span
      v-if="hasRemove"
      class="remove"
      @click="$emit('setRemoveVideo', video)"
    >
      <DeleteBinLineIcon />
    </span>

    <span
      v-if="(video.bullets || []).length > 0 && hasBulletEdit"
      class="bullet"
      @click="$emit('setEditBullet', video)"
    >
      <component :is="`${video.bullets[0].icon}-icon`" />
    </span>

    <span
      v-else-if="(video.bullets || []).length < 1 && hasBulletEdit"
      class="bullet add"
      @click="$emit('setEditBullet', video)"
    >
      Nova tabela
      <AddLineIcon />
    </span>

    <span class="title">
      {{ video.name }}
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    hasSelect: {
      type: Boolean,
      default: () => false
    },

    hasBulletEdit: {
      type: Boolean,
      default: () => false
    },

    video: {
      type: Object,
      default: () => {}
    },

    componentId: {
      type: String,
      default: () => 'library'
    },

    hasRemove: {
      type: Boolean,
      default: () => true
    }
  },

  emits: [
    'setRemoveVideo',
    'setEditBullet'
  ],

  data: () => ({
    selected: false
  }),

  methods: {
    ...mapActions(['setLibraryVideosSelected']),

    setVideoSelected(video, status) {
      this.setLibraryVideosSelected({ video: {...video}, status });
    }
  }
}
</script>

<style lang="scss" scoped>
.library-video__card {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 280px;
    position: relative;

   .preview {
    width: 100%;
    height: 360px;
    border-radius: 8px;
    object-fit: cover;
   }

   input {
    display: none;

    &:checked {
      & + .check:before {
        background: $blue-500;
      }

      & + .check:after {
        display: flex;
      }
    }
  }

  .check {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;

    &:before {
      content: '';
      display: flex;
      width: 19px;
      height: 19px;
      background: $white;
      border-radius: 4px;
    }

    &:after { 
      content: '';
      display: none;
      width: 8px;
      height: 4px;
      border-left: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      position: absolute;
      top: 5px;
      left: 5px;
      transform: rotate(-45deg);
    }
  }

  .remove, .bullet {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: $red-500;
    cursor: pointer;

    svg {
      color: $red-500;
      width: 17px;
    }
  }

  .bullet {
    top: 50px;

    &.add {
      width: max-content;
      border-radius: 50px;
      gap: 10px;
      padding: 0 10px;
      background: $black-500;
      color: $white;
      font-size: 13px;
      
      svg {
        color: $white;
      }
    }

    svg {
      color: $black-500;
    }
  }

  .title {
    font-size: 15px !important;
    font-weight: normal !important;
    color: $grayscale-400 !important;
    text-align: left;
    margin-top: 13px;
    line-height: 20px;
    white-space: nowrap;        
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
}
</style>