export default {
  namespaced: true,

  state: {
    veels: {},
    veelsFilters: {
      page: 1,
      per_page: 10,
      name: ''
    },
    veelsDetails: {}
  },

  getters: {
    getVeels: state => state.veels,
  },

  mutations: {
    SET_VEELS: (state, value) => (state.veels = value),
    SET_VEELS_DETAILS: (state, value) => (state.veelsDetails = value)
  },

  actions: {
    setVeelsFilters ({ state }, data) {
      Object.assign(state.veelsFilters, {
        ...data
      })
    },

    setVeelsDetails ({ state }, data) {
      if (Object.keys(data).length < 1) {
        return state.veelsDetails = {}
      }

      Object.assign(state.veelsDetails, {
        ...data
      })
    },

    async searchVeels({ commit, state }) {
      let value = null;
      let error = null;

      const {page, per_page, name } = state.veelsFilters
      const queryParams = new URLSearchParams({
        page,
        per_page,
        name
      }).toString()

      try {
        const response = await window
          .$request()
          .get(`/veels/search?${queryParams}`)

        commit('SET_VEELS', response.data);

        value = response.data;
      } catch (e) {
        console.error('[searchVeels]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async searchVeelsDetails({ commit }, id) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .get(`/veels/${id}`)

        commit('SET_VEELS_DETAILS', response.data?.veels || {});

        value = response.data;
      } catch (e) {
        console.error('[searchVeelsDetails]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async veelsLinkTerms ({ dispatch }, { veels_id, terms }) {
      let value = null;
      let error = null;

      try {
        value = await window
          .$request()
          .post(`/link-terms/save`, {
            veels_id,
            links: terms
          });

        dispatch('setVeelsDetails', {links: value?.data?.links || []});

      } catch (e) {
        error = e;
      }

      return {
        error,
        value
      };
    },

    async veelsUnlinkTerms ({ dispatch, state }, { veels_id, term_id }) {
      let value = null;
      let error = null;

      try {
        value = await window
          .$request()
          .delete(`/link-terms/${veels_id}/${term_id}`);

        const links = state.veelsDetails.links.filter(l => l.id !== term_id);
        dispatch('setVeelsDetails', {links: [...links]});

      } catch (e) {
        error = e;
      }

      return {
        error,
        value
      };
    },

    async removeVideoFromVeels({ dispatch, state }, data) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .delete(`/link-videos/${data.veels_id}/${data.id}`)

        const videosData = state.veelsDetails.videos.filter(v => v.id !== data.id);

        dispatch('setVeelsDetails', {videos: videosData });

        value = response.data;
      } catch (e) {
        console.error('[removeVideoFromLibrary]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async updateVeelsInfo ({ commit }, data) {
      let value = null;
      let error = null;

      let _payload = {...data}
      delete _payload.id;

      try {
        value = await window
          .$request()
          .put(`/veels/${data.id}`, {
            ..._payload
          });

          console.log(commit);
      } catch (e) {
        error = e;
      }

      return {
        error,
        value
      };
    }
  }
}
