<template>
  <div
    v-if="popupNewVeelsActive"
    class="new-veels__background-close"
    @click="closeUploadPopup()"
  />

  <div
    v-show="popupNewVeelsActive"
    class="new-veels"
  >
    <div class="new-veels__header">
      <span
        class="close"
        @click="closeUploadPopup()"
      >
        <CloseLineIcon />
      </span>
    </div>

    <div
      v-if="newVeelsStep !== 'success'"
      class="new-veels__steps"
    >
      <div
        class="step"
        :class="{
          active: newVeelsStep == 'upload',
          checked: checkedUploadStep
        }"
      >
        1. Upload de vídeo
      </div>

      <div
        class="step"
        :class="{
          active: newVeelsStep == 'bullets',
          checked: checkedUploadStep
        }"
      >
        2. Tabela de informações (opcional)
      </div>

      <div
        class="step"
        :class="{
          active: newVeelsStep == 'product',
          checked: checkedProductStep
        }"
      >
        3. Vincule a produtos
      </div>

      <div
        class="step"
        :class="{
          active: newVeelsStep == 'publish',
          checked: checkedPublishStep
        }"
      >
        3. Revise e publique
      </div>
    </div>

    <UploadVeels v-show="newVeelsStep == 'upload'" />
    <BulletsVeels v-show="newVeelsStep == 'bullets'" />
    <ProductsVeels v-show="newVeelsStep == 'product'" />
    <PublishVeels v-show="newVeelsStep == 'publish'" />
    <SuccessVeels v-show="newVeelsStep == 'success'" />
  </div>
</template>

<script>
import UploadVeels from './UploadVeels.vue';
import BulletsVeels from './BulletsVeels.vue';
import ProductsVeels from './ProductsVeels.vue';
import PublishVeels from './PublishVeels.vue';
import SuccessVeels from './SuccessVeels.vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    UploadVeels,
    ProductsVeels,
    PublishVeels,
    SuccessVeels,
    BulletsVeels
  },

  data: () => ({
    popupNewVeelsActive: false
  }),

  computed: {
    ...mapState(['newVeelsStep', 'newVeelsForm']),
    checkedUploadStep () {
      return (this.newVeelsForm?.videos || []).length > 0
    },
    checkedProductStep () {
      return (this.newVeelsForm?.links || []).length > 0
    },
    checkedPublishStep () {
      return this.newVeelsForm?.name && this.newVeelsForm?.description
    }
  },

  mounted () {
    this.emitter.on('toggle-popup-new-veels', this.togglePopupNewVeels);
  },

  unmounted () {
    this.emitter.off('toggle-popup-new-veels', this.togglePopupNewVeels);
  },

  methods: {
    ...mapActions(['setNewVeelsForm', 'setLibraryVideosSelected', 'setNewVeelsStep']),
    closeUploadPopup () {
      this.popupNewVeelsActive = false;
      this.setNewVeelsForm({});
      this.setLibraryVideosSelected({ video: {}, status: 'clear' });
    },

    togglePopupNewVeels(status) {
      this.popupNewVeelsActive = !!status;
      this.setNewVeelsStep('upload');

      if (status === 'library') {
        this.emitter.emit('toggle-upload-from-library-new-veels', true);
      }

      if (status === 'mobile') {
        this.emitter.emit('toggle-upload-from-mobile-new-veels', true);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-veels__background-close {
  z-index: 9;
  background: rgba(0,0,0,0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}

.new-veels {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  z-index: 10;
  width: 90vw;
  height: 90vh;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  padding: 0;

  &__header {
    width: max-content;
    display: flex;

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      display: flex;
      cursor: pointer;

      svg {
        width: 20px;
        color: $grayscale-400;
      }
    }
  }

  &__steps {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    gap: 50px;
    margin: 50px auto;
    padding: 0 50px;
    border-radius: 8px;
    border: 1px solid $grayscale-300;
    height: 64px;

    .step {
      color: $grayscale-400;
      font-size: 15px;
      height: 100%;
      display: flex;
      align-items: center;

      &.active {
        color: $black-500;
        font-weight: bold;
        position: relative;
        justify-content: center;

        &:after {
          content: '';
          width: 40px;
          height: 3px;
          background: $black-500;
          position: absolute;
          bottom: 0;
          border-radius: 10px;
        }
      }

      &.checked {
        color: $black-500;
        font-weight: bold;
        position: relative;
        justify-content: center;

        &:before {
          content: '';
          width: 13px;
          height: 5px;
          border-left: 3px solid $green-500;
          border-bottom: 3px solid $green-500;
          position: absolute;
          top: 25px;
          left: -23px;
          z-index: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
</style>