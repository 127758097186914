<template>
  <FormKit
    v-slot="{ state }"
    v-model="formData"
    type="form"
    :actions="false"
    form-class="veels-details-info-form"
    incomplete-message="Por favor, preencha os campos corretamente"
    @submit="handleSubmit"
  >
    <FormKit
      type="text"
      placeholder="digite o título do veels"
      label="Título do veels (uso interno)"
      name="name"
    />

    <FormKit
      type="text"
      placeholder="digite o texto da chamada"
      label="Texto da chamada para clique no vídeo"
      name="description"
    />

    <FormKit
      type="hidden"
      :value="$route.params.veels_id"
      name="id"
    />

    <ButtonDefault
      :loading="state?.loading"
    >
      Salvar
    </ButtonDefault>
  </FormKit>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: "VeelsDetailsInfo",

  data: () => ({
    formData: {
      name: "",
      description: "",
    }
  }),

  computed: {
    ...mapState(['veelsDetails']),
  },

  watch: {
    veelsDetails: {
      deep: true,
      immediate: true,
      handler (vd) {
        this.formData.name = vd?.name || ''
        this.formData.description = vd?.description || ''
      }
    }
  },

  created () {
    this.formData.name = this.veelsDetails?.name || ''
    this.formData.description = this.veelsDetails?.description || ''
  },

  methods: {
    ...mapActions(['updateVeelsInfo', 'setVeelsDetails']),

    async handleSubmit(payload) {
      const { error } = await this.updateVeelsInfo(payload);
      if (error) {
        return alert(`Falha ao atualizar as informações, por favor tente mais tarde.`);
      }

      this.setVeelsDetails({
        name: payload.name,
        description: payload.description
      })

      return alert('Informações atualizadas com sucesso!');
    }
  }
}
</script>

<style lang="scss">
.veels-details-info-form {
  max-width: 800px;
  display: flex;
  flex-flow: nowrap !important;
  align-items: flex-end !important;
  gap: 10px;

  .button-default {
    width: 110px;
    min-width: 110px;
    height: 55px;
    justify-content: center;
  }
}
</style>